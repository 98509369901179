import React from 'react';
import { NextSeo } from 'next-seo';
import { GetStaticProps } from 'next';
import { useLocation } from 'react-use';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import CourseIntroduction from '@/components/Home/CourseIntroduction';
import UserReviews from '@/components/Home/UserReviews';
import Teachers from '@/components/Home/Teachers';
import TeachingResearch from '@/components/Home/TeachingResearch';
import Honor from '@/components/Home/Honor';
import Blog from '@/components/Home/Blog';
import Layout from '@/components/Layout';
import Questions from '@/components/Home/Questions';
import Banner from '@/components/Home/Banner';
import OnlineClass from '@/components/Home/OnlineClass';
// import { BLOG_URL } from '@/utils/constants';

// import { useLenis } from '@studio-freight/react-lenis';

export const getStaticProps: GetStaticProps = async ({ locale = '' }) => {
  // 获取多语言翻译
  const translations = await serverSideTranslations(locale, ['common', 'home', 'path', 'form']);
  return {
    props: {
      ...translations,
    },
  };
};

const Home = () => {
  const state = useLocation();
  const { t } = useTranslation(['home', 'common']);

  // const lenis = useLenis(({ scroll }) => {
  //   console.log('scroll', scroll);
  //   // called every scroll
  // });

  // useLenis((lenis) => {
  //   console.log('Current page progress', lenis.scroll, lenis.limit);
  // });

  return (
    <>
      <NextSeo
        openGraph={{
          url: state?.href,
          title: t('common:seo-首页-title'),
          description: t('common:seo-首页-description'),
          images: [
            {
              url: 'https://cdnwukong.com/images/public/og/offical-site-1.png',
              width: 300,
              height: 300,
              alt: t('common:seo-首页-title'),
            },
          ],
        }}
      />
      <Head>
        <title>{t('common:seo-首页-title')}</title>
        <meta name="description" content={t('common:seo-首页-description')} />
        <meta name="keywords" content={t('common:seo-首页-关键词')} />
        <meta
          data-n-head="ssr"
          data-hid="apple-mobile-web-app-title"
          name="apple-mobile-web-app-title"
          content={t('common:seo-首页-title')}
        />
        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async />
        {/* SEO 仅首页 开始 */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'Organization',
              title: t('common:seo-首页-title'),
              url: 'https://www.wukongsch.com',
              logo: 'https://cdnwukong.com/images/public/og/offical-site-1.png',
              brand: {
                '@type': 'Brand',
                name: 'WuKong Education',
              },
            }),
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'Product',
              name: t('common:seo-首页-title'),
              description: t('common:seo-首页-description'),
              url: 'https://www.wukongsch.com',
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: '4.7',
                ratingCount: '36805',
                reviewCount: '1529',
              },
              brand: {
                '@type': 'Brand',
                name: 'WuKong Education',
              },
              operatingSystem: 'WINDOWS, MAC, iOS, ANDROID',
              offers: {
                '@type': 'AggregateOffer',
                offerCount: '999999999',
                lowPrice: '0.00',
                highPrice: '0.00',
                priceCurrency: 'USD',
              },
            }),
          }}
        />
        {/* SEO 仅首页 结束 */}
      </Head>
      <main className="relative overflow-hidden">
        {/* pc 拆减两边 移动端铺满 */}
        <div className="absolute left-1/2 top-0 h-[758px] w-full translate-x-[-50%]  bg-[url('/images/home/bg-m.svg')] bg-cover  bg-center bg-no-repeat md:h-[5340px] md:w-[1920px] md:bg-[url('/images/home/bg.svg')]" />
        <div className="relative z-10 bg-transparent">
          <Banner />
          {/* 线上课堂 */}
          <OnlineClass />
          {/* 课程介绍 */}
          <CourseIntroduction />
          {/* 师资 */}
          <Teachers />
          {/* 用户评价 */}
          <UserReviews />
          {/* 教学研发 */}
          <TeachingResearch />
          {/* 荣誉 */}
          <Honor />
          {/* 博客 */}
          <Blog exposeEvent="O_Home_BlogExpose" />
          {/* 问题 */}
          <Questions exposeEvent="O_Home_QuestionExpose" />
        </div>
      </main>
    </>
  );
};

export default Home;

Home.getLayout = (page: React.ReactElement) => <Layout tooterTheme="home">{page}</Layout>;
