import React, { useState } from 'react';
import Title from '@/components/Title';
import cls from 'classnames';
import Button from '@/components/Button';
import LeadButton from '@/components/LeadButton';

import Arrow2 from '@/components/Icon/Arrow2';
import Image from 'next/image';
import useIsMobile from '@/hooks/useIsMobileNew';

import { useTranslation } from 'next-i18next';

const CourseIntroduction = () => {
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation(['home', 'path']);
  // 0 全部默认
  // 1 2 3 当前的展开 不一致的用收缩样式
  const [mouseOverTriggered, setMouseOverTriggered] = useState(0);

  const handleMouseover = (id: number) => {
    if (!isMobile) {
      setMouseOverTriggered(id);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setMouseOverTriggered(0);
    }
  };

  // 移动端是pc展开的样式
  // pc hover 展开样式
  // pc 默认样式
  // pc hover 隐藏样式

  const config = [
    {
      title: '课程介绍-中文-标题',
      subTitle: '课程介绍-中文-附标题',
      infoList: ['课程介绍-中文-优势-1', '课程介绍-中文-优势-2', '课程介绍-中文-优势-3'],
      img: '/images/home/class-chinese-ip.png',
      imgLogo: '/images/home/logo-chinese.png',
      age: '课程介绍-中文-年级',
      subject: 'chinese',
      href: 'path:中文课程-首页-banner',
      hrefMore: '/chinese/',
      id: 1,
      ga: 'CourseModule_ChineseCTAClick',
      ga4: 'ChineseCTA',
      gaMoreBtn: 'CourseModule_ChineseMoreClick',
    },
    {
      title: '课程介绍-数学-标题',
      subTitle: '课程介绍-数学-附标题',
      infoList: ['课程介绍-数学-优势-1', '课程介绍-数学-优势-2', '课程介绍-数学-优势-3'],
      img: '/images/home/class-math-ip.png',
      imgLogo: '/images/home/logo-math.png',
      age: '课程介绍-数学-年级',
      subject: 'math',
      href: 'path:中文课程-首页-banner',
      id: 2,
      hrefMore: '/math/',
      ga: 'CourseModule_MathCTAClick',
      ga4: 'MathCTA',
      gaMoreBtn: 'CourseModule_MathMoreClick',
    },
    {
      title: '课程介绍-英文-标题',
      subTitle: '课程介绍-英文-附标题',
      infoList: ['课程介绍-英文-优势-1', '课程介绍-英文-优势-2', '课程介绍-英文-优势-3'],
      img: '/images/home/class-english-ip.png',
      imgLogo: '/images/home/logo-english.png',
      age: '课程介绍-英文-年级',
      subject: 'english',
      href: 'path:中文课程-首页-banner',
      hrefMore: '/english/',
      id: 3,
      ga: 'CourseModule_EnglishCTAClick',
      ga4: 'EnglishCTA',
      gaMoreBtn: 'CourseModule_EnglishMoreClick',
    },
  ];

  return (
    <section className="" data-expose-event="O_Home_CourseIntroductionExpose">
      <div className="relative mb-6 mt-6 h-[214px] overflow-hidden bg-[url('/images/home/class-line-m.png')] bg-cover bg-center bg-no-repeat lg:mb-10 lg:mt-10 lg:h-[189px] lg:bg-[url('/images/home/class-line.png')] xl:mb-12 xl:mt-15    xl:h-[265px]">
        <Title className="mx-auto mt-[132px] px-6 lg:mt-[89px] xl:mt-[117px]" innerHTML theme="colorful">
          {t('课程介绍-标题')}
        </Title>
      </div>
      <div className="container">
        <ul className="flex flex-col space-y-6  lg:flex-row lg:space-x-4 lg:space-y-0 xl:space-x-6">
          {config.map((item) => (
            <li
              // 暂时不用 flex-1 页面抖动厉害
              className={cls(
                ' relative rounded-3xl border-4 border-white px-5 pb-10  pt-5 shadow-wk2 backdrop-blur-sm transition-all  duration-300 ease-in-out lg:h-[380px] lg:w-[317px] lg:px-6 lg:pb-15 lg:pt-6 xl:h-[530px] xl:w-[451px] xl:rounded-5xl xl:px-8 xl:pb-[88px]',
                item.subject === 'chinese' &&
                  'bg-white bg-[radial-gradient(103.11%_100.58%_at_39.34%_0%,#FFF0F0_0%,rgba(255,255,255,0.00)_72.33%)]',
                item.subject === 'math' &&
                  'bg-white bg-[radial-gradient(124.91%_100.57%_at_39.34%_0%,#F0F8FF_0%,rgba(255,255,255,0.00)_72.33%)]',
                item.subject === 'english' &&
                  'bg-white bg-[radial-gradient(86.05%_100.95%_at_39.34%_0%,#F5EFFF_0%,_rgba(255,255,255,0.00)_72.33%)] ',
                // 当前hover样式
                item.id === mouseOverTriggered && '[&]:lg:w-[492px] [&]:xl:w-[688px]',
                // 其余被hover 缩短样式
                item.id !== mouseOverTriggered && mouseOverTriggered > 0 && '[&]:lg:w-[230px] [&]:xl:w-[332px]',
              )}
              key={item.id}
              onMouseEnter={() => {
                handleMouseover(item.id as number);
              }}
              onMouseLeave={handleMouseLeave}
            >
              <h3
                className={cls(
                  'text-[28px] font-bold leading-normal lg:text-[34px]  lg:font-bold lg:leading-normal xl:text-5xl xl:leading-normal',
                  i18n.language === 'zh' && 'font-black ',
                )}
              >
                {t(item.title)}
              </h3>
              <span
                className={cls(
                  'absolute right-5 top-[26px] z-10 inline-block rounded-[2px_10px_10px_10px] border border-white  px-3 py-1 text-xs font-semibold leading-normal text-white shadow-[0px_4px_9px_rgba(0,0,0,0.08)] lg:right-[165px] lg:top-[260px] lg:rounded-[12px_2px_12px_12px] lg:text-sm lg:leading-normal xl:right-[234px] xl:top-[363px] xl:rounded-[16px_2px_16px_16px] xl:px-4 xl:py-1.5 xl:text-lg',
                  item.subject === 'chinese' &&
                    'bg-[linear-gradient(278deg,rgba(255,176,83,0.61)-3.09%,rgba(255,83,83,0.75)_56.31%,rgba(252,50,86,0.75)_100.16%)]',
                  item.subject === 'math' &&
                    'bg-[linear-gradient(276deg,rgba(0,178,255,0.46)_-0.81%,rgba(0,146,255,0.60)_43.33%,rgba(0,56,255,0.60)_100.14%)]',
                  item.subject === 'english' &&
                    'bg-[linear-gradient(274deg,rgba(248,66,186,0.36)_0.09%,rgba(134,66,248,0.70)_45.06%,rgba(70,66,248,0.70)_100.09%);]',
                  // 当前hover样式
                  item.id === mouseOverTriggered &&
                    '[&]:lg:right-8 [&]:lg:top-8  [&]:lg:rounded-[2px_12px__12px_12px] ',
                  // 其余被hover 缩短样式
                  item.id !== mouseOverTriggered &&
                    mouseOverTriggered > 0 &&
                    'mt-6 [&]:static [&]:border-wk-border-3 [&]:bg-white  [&]:bg-none [&]:text-wk-words-2 [&]:lg:rounded-[2px_12px_12px_12px]',
                )}
              >
                {t(item.age)}
              </span>
              <p
                className={cls(
                  'mt-2 text-base leading-normal text-wk-words-2  transition-all  duration-300 ease-in-out lg:max-h-0  lg:overflow-hidden lg:text-xl  lg:opacity-0 xl:text-[28px]',
                  // 当前hover样式
                  item.id === mouseOverTriggered && '[&]:lg:max-h-full  [&]:lg:overflow-visible [&]:lg:opacity-100',
                  // 其余被hover 缩短样式
                  item.id !== mouseOverTriggered && mouseOverTriggered > 0 && ' ',
                )}
              >
                {t(item.subTitle)}
              </p>
              <div
                className={cls(
                  'relative z-10 mt-6 flex flex-col space-y-3 text-sm lg:mt-4 xl:mt-6 xl:space-y-4 xl:text-xl ',
                  // 当前hover样式
                  item.id === mouseOverTriggered && 'last:[&_h4]:lg:pr-25 last:[&_h4]:xl:pr-[120px] ',
                  // 其余被hover 缩短样式
                  item.id !== mouseOverTriggered && mouseOverTriggered > 0 && ' hidden',
                )}
              >
                {item.infoList.map((infoListItem, index) => (
                  <h4 key={index} className="flex  items-center  ">
                    <p>
                      <span
                        className={cls(
                          ' mr-2 inline-block h-8 w-8  rounded-[10px]  bg-clip-text text-center text-lg font-bold leading-8 text-transparent shadow-[0px_16px_24px_rgba(0,0,0,0.06)] lg:h-7 lg:w-7 lg:text-lg xl:h-10 xl:w-10 xl:text-2xl',
                          item.subject === 'chinese' && 'bg-[linear-gradient(181deg,#FF5353_42.01%,#FBB938_94.75%)]',
                          item.subject === 'math' && 'bg-[linear-gradient(181deg,#0092FF_37.14%,#00FFE0_94.75%)]',
                          item.subject === 'english' && 'bg-[linear-gradient(181deg,#8642F8_37.14%,#F442F8_94.75%)]',
                        )}
                      >
                        {index + 1}
                      </span>
                    </p>
                    <p
                      className={cls(
                        'lg:line-clamp-1', // 当前hover样式
                        item.id === mouseOverTriggered && 'lg:line-clamp-none',
                        // 其余被hover 缩短样式
                        item.id !== mouseOverTriggered && mouseOverTriggered > 0 && 'lg:line-clamp-1',
                      )}
                    >
                      {t(infoListItem)}
                    </p>
                  </h4>
                ))}
              </div>
              <div
                className={cls(
                  '  relative z-10  transition-all duration-300 ease-in-out   lg:opacity-0 ',
                  // 当前hover样式
                  item.id === mouseOverTriggered && ' [&]:lg:opacity-100',
                  // 其余被hover 缩短样式
                  item.id !== mouseOverTriggered && mouseOverTriggered > 0 && '',
                )}
              >
                <div className="flex flex-col lg:mt-6 lg:flex-row lg:flex-nowrap lg:space-x-6 xl:mt-9">
                  <div>
                    <LeadButton
                      traceName={`O_Home_${item.ga}`}
                      traceGa4Name={`new_entry_HomePage_CourseModule_${item.ga4}`}
                      theme="colorfulBorder"
                      subject={item.subject as 'chinese' | 'math' | 'english'}
                      className="mt-10  whitespace-nowrap  px-6 py-2 text-lg   lg:mt-0 lg:px-8  lg:py-3 lg:text-sm xl:px-[51px] xl:py-4 xl:text-lg"
                    >
                      {t('课程介绍-按钮-卡片试听')}
                    </LeadButton>
                  </div>
                  <div>
                    <Button
                      traceName={`O_Home_${item.gaMoreBtn}`}
                      traceData={{ button_name: t('课程介绍-按钮-卡片浏览') }}
                      href={item.hrefMore}
                      theme="empty"
                      className="ml-6 mt-3 whitespace-nowrap  text-sm text-wk-words-3  lg:ml-0 lg:mt-0 lg:border lg:border-wk-words-3 lg:px-8 lg:py-3 xl:px-[51px]  xl:py-4  xl:text-lg"
                    >
                      <>
                        {t('课程介绍-按钮-卡片浏览')}
                        <Arrow2 className="inline-block h-4 w-4 -rotate-90 lg:hidden" />
                      </>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="absolute -bottom-1 -right-1  h-[170px]  w-[250px]  lg:h-[197px] lg:w-72 xl:h-[280px]  xl:w-[410px]">
                <Image alt={`${t('悟空')}${t(item.title)}`} width={410} height={280} src={item.img} />
              </div>
              <div
                className={cls(
                  'absolute  hidden h-[32px]  w-[66px]  lg:bottom-[10px] lg:block  xl:bottom-6 xl:h-10 xl:w-[82px]',
                  // 当前hover样式
                  item.id === mouseOverTriggered && '[&]:hidden',
                  // 其余被hover 缩短样式
                  item.id !== mouseOverTriggered && mouseOverTriggered > 0 && '[&]:hidden',
                )}
              >
                <Image alt="Wukong" width={82} height={40} src={item.imgLogo} />
              </div>
            </li>
          ))}
        </ul>
        <div className="hidden text-center lg:block">
          <LeadButton
            traceName="O_Home_CourseModule_CTAClick"
            traceGa4Name="new_entry_HomePage_CourseModuleCTA"
            theme="colorfulBorder"
            size="xl"
            className="mt-15 xl:mt-20"
          >
            {t('课程介绍-按钮-试听')}
          </LeadButton>
        </div>
      </div>
    </section>
  );
};

export default CourseIntroduction;
