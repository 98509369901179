// BlogPostItem.tsx

import React from 'react';
import Image from 'next/image';
import cls from 'classnames';
import { Post } from '@/request/interface/common';
import Eye from '../Icon/Eye';
import TagItem from './TagItem';

interface BlogPostItemProps {
  post: Post[];
  className?: string;
  handleClick?: (post: Post) => void;
}

const BlogPostItem: React.FC<BlogPostItemProps> = ({ post, className, handleClick }) => {
  return (
    <div
      className={cls(
        'mx-auto grid max-w-96  grid-cols-1 gap-6  sm:max-w-full sm:grid-cols-2 md:grid-cols-3   lg:gap-4  xl:gap-6',
        className,
      )}
    >
      {post?.map((item) => (
        <a
          onClick={() => handleClick?.(item)}
          href={item.post_link}
          key={item.post_id || item.post_link}
          target="_blank"
          className="group rounded-3xl  shadow-wk2 transition-all duration-500 ease-in-out hover:shadow-[0px_12px_30px_rgba(0,0,0,0.1)] xl:rounded-5xl"
        >
          <article className="flex h-full flex-col">
            <div className="relative h-[188px] overflow-hidden rounded-t-3xl md:h-[180px] xl:h-[255px] xl:rounded-t-5xl">
              <Image
                fill
                className="scale-100 rounded-t-3xl object-cover transition-transform duration-500 ease-in-out group-hover:scale-110 lg:rounded-t-5xl"
                src={item.thumbnail ? item.thumbnail : '/images/home/blog-post-default.jpg'}
                alt={item.post_title}
                sizes="(max-width: 768px) 100vw, (max-width: 1024px) 33vw, 33vw"
              />
            </div>
            <div className=" flex flex-1 flex-col justify-between px-4 pb-4 pt-3 lg:px-6 lg:pb-6 lg:pt-4">
              <div>
                <h3 className="line-clamp-2 text-left text-sm font-semibold lg:text-base xl:text-xl">
                  {item?.post_title}
                </h3>
              </div>
              {!!item?.tags?.length && <TagItem tags={item?.tags} />}
              <div className="mt-3 flex items-center  justify-between text-[14px] text-[#8D8FA6] lg:mt-4">
                <div className="flex items-center gap-[3px]">
                  {!!item?.post_views && (
                    <>
                      <Eye />
                      <span>{item?.post_views}</span>
                    </>
                  )}
                </div>
                {!!item?.post_time && <span>{item?.post_time}</span>}
              </div>
            </div>
          </article>
        </a>
      ))}
    </div>
  );
};

export default BlogPostItem;
