import React from 'react';

interface CustomLinkProps {
  children: React.ReactNode;
  className?: string;
}

const TPLink = ({ className, children }: CustomLinkProps) => {
  return (
    <a
      className={className}
      href="https://www.trustpilot.com/review/wukongsch.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export default TPLink;
