import React from 'react';

import Questions from '@/components/Questions';

const Accordion = ({exposeEvent}: {exposeEvent?: string}) => {
  const questionsAnswers = [
    {
      question: '常见问题-1-问题',
      answer: '常见问题-1-答案',
      id: 1,
    },
    {
      question: '常见问题-2-问题',
      answer: '常见问题-2-答案',
      id: 2,
    },
    {
      question: '常见问题-3-问题',
      answer: '常见问题-3-答案',
      id: 3,
    },
    {
      question: '常见问题-4-问题',
      answer: '常见问题-4-答案',
      id: 4,
    },
    {
      question: '常见问题-5-问题',
      answer: '常见问题-5-答案',
      id: 5,
    },
    {
      question: '常见问题-6-问题',
      answer: '常见问题-6-答案',
      id: 6,
    },
  ];

  return (
    <Questions
      questionsAnswers={questionsAnswers}
      useTranslationName="home"
      className="marginModelTop container mb-20  lg:mb-[100px]  xl:mb-40 "
      exposeEvent={exposeEvent}
    />
  );
};

export default Accordion;
