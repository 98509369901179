import React, { useState, useRef, useEffect } from 'react';
import Title from '@/components/Title';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Arrow2 from '@/components/Icon/Arrow2';
import cls from 'classnames';
import { traceEvent } from '@wk/wk-gatherer';
import useGTM from '@/hooks/useGtag';

interface IAccordionItem {
  question: string;
  answer: string;
  id: number;
}

interface QuestionsProps {
  questionsAnswers: IAccordionItem[];
  className?: string;
  // 页面需要引用对应页面的多语言
  useTranslationName: string;
  exposeEvent?: string;
  subject?: 'chinese' | 'english' | 'math';
}

interface IAccordionItemCom extends IAccordionItem {
  useTranslationName: string;
  index: number;
  handleGA: (gaName?: string, extra?: { [key: string]: any }) => void;
}

const arrowCss = 'h-3 w-3 xl:h-5 xl:w-5';

const AccordionItem = ({ question, answer, useTranslationName, index, handleGA }: IAccordionItemCom) => {
  const { t } = useTranslation([useTranslationName]);
  const [isActive, setIsActive] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    setIsActive(!isActive);
    handleGA('Click', {
      question_number: index,
      question_name: t(question),
    });
  };

  return (
    <div className="relative transition-all duration-500">
      <div onClick={toggleAccordion} className="cursor-pointer pb-3 pt-3 lg:pb-8 lg:pt-8">
        <div className="flex items-center justify-between">
          <h3 className="pr-3 text-sm font-semibold lg:text-lg xl:text-xl">{t(question)}</h3>
          <span className={`transform transition-transform duration-300 ${isActive ? '-rotate-180' : ''}`}>
            <Arrow2 className={cls(arrowCss)} />
          </span>
        </div>
      </div>

      <div
        ref={contentRef}
        style={{ maxHeight: isActive ? `${contentRef?.current?.scrollHeight || 0}px` : '0' }}
        className="relative overflow-hidden text-xs leading-5 transition-all duration-500 lg:text-sm lg:leading-6 xl:text-base xl:leading-6"
      >
        <div
          className="pb-3 text-wk-words-3 lg:pb-8 [&_a]:text-wk-blue-400 [&_a]:underline [&_a]:decoration-wk-blue-400 [&_a]:underline-offset-2 [&_strong]:font-semibold"
          dangerouslySetInnerHTML={{ __html: t(answer) }}
        />
      </div>
    </div>
  );
};

const Questions: React.FC<QuestionsProps> = ({
  questionsAnswers,
  useTranslationName,
  className,
  exposeEvent,
  subject,
}) => {
  const { t, i18n } = useTranslation([useTranslationName, 'common']);
  const sendDataToGTM = useGTM();
  const router = useRouter();
  const currentPath = router.pathname;
  const [key, setKey] = useState<number>(0);
  const handleGA = (gaName?: string, extra: { [key: string]: any } = {}) => {
    // 获取当前路径
    const gaNameFix = `O_FaqModule_${gaName}`;
    sendDataToGTM(gaNameFix);
    traceEvent({
      data: {
        _event: gaNameFix,
        ...extra,
        // 点击的所有页面名称
        page_name: currentPath,
      },
    });
  };

  // 切换语言后重新渲染 避免文案更新高度不一致
  useEffect(() => {
    if (!router.isReady) return;
    setKey(key + 1);
  }, [i18n.language]);

  return (
    <section className={cls(className)} data-expose-event={exposeEvent}>
      <Title className="marginTitleBottom">{t('common:常见问题-标题')}</Title>
      <div key={key} className="maxModuleWidth rounded-3xl bg-[#F7F8FF] px-5 py-5 lg:rounded-5xl lg:px-8 lg:py-0">
        {questionsAnswers.map((qa, index) => (
          <div key={qa.id} className="border-b border-wk-border-1  [&:last-child]:border-b-0">
            <AccordionItem
              id={qa.id}
              question={qa.question}
              answer={qa.answer}
              useTranslationName={useTranslationName}
              handleGA={handleGA}
              index={index + 1}
            />
          </div>
        ))}
      </div>
      {subject === 'chinese' ? (
        <Link
          onClick={() => {
            handleGA('MoreClick');
          }}
          href="/faq/"
          className="mt-6 flex items-center justify-center text-sm lg:mt-12  lg:text-base xl:mt-15  xl:text-xl"
        >
          {t('common:常见问题-更多')} <Arrow2 className={cls('ml-0.5  -rotate-90 ', arrowCss)} />
        </Link>
      ) : (
        <div className="mt-6 flex lg:mt-14 xl:mt-16" />
      )}
    </section>
  );
};

export default Questions;
