import React, { useState, useRef, useEffect } from 'react';
import Title from '@/components/Title';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useGTM from '@/hooks/useGtag';

import cls from 'classnames';
import Button from '@/components/Button';
import { traceEvent } from '@wk/wk-gatherer';
import BlogPostItem from '@/components/BlogPostItem';
import BScroll from '@better-scroll/core';

import { useTranslation } from 'next-i18next';
import { Post } from '@/request/interface/common';
import { getBlogList } from '@/request/common';
import dayjs from 'dayjs';

export interface Cat {
  cat_id: number;
  cat_name: string;
  cat_link: string;
}

export interface Tag {
  tag_id: number;
  tag_name: string;
  tag_link: string;
}

export interface HomeBlogItem {
  cat: Cat;
  posts: Post[];
}

export interface IBlogProps {
  exposeEvent?: string;
}

const Blog = ({ exposeEvent }: IBlogProps) => {
  const { t, i18n } = useTranslation(['home', 'path']);
  const sendDataToGTM = useGTM();
  const router = useRouter();
  const currentPath = router.pathname;
  const divRef = useRef<HTMLDivElement>(null);
  //  存储better-scroll的实例
  const [scrollObj, setscrollObj] = useState<any>();
  const [blogList, setBlogList] = useState<HomeBlogItem[]>([]);
  const [currentKey, setCurrentKey] = useState<HomeBlogItem>();

  useEffect(() => {
    const storeName = `blog-list-home-${i18n.language}`;
    const blogs = JSON.parse(localStorage.getItem(storeName) || '{}');
    if (dayjs().format('YYYY-MM-DD') === blogs?.date) {
      setBlogList(blogs?.data || []);
      setCurrentKey(blogs?.data?.[0] || {});
      return;
    }
    getBlogList({ lang: i18n.language || 'en' }).then((res) => {
      const data = res?.slice(0, 5) || [];
      setBlogList(data);
      setCurrentKey(data?.[0] || {});
      localStorage.setItem(storeName, JSON.stringify({ data: res, date: dayjs().format('YYYY-MM-DD') }));
    });
  }, [i18n.language]);

  const handleGA = (gaName?: string, extra: { [key: string]: any } = {}) => {
    // 获取当前路径
    const gaNameFix = `O_Home_BlogModule_${gaName}`;
    sendDataToGTM(gaNameFix);
    traceEvent({
      data: {
        _event: gaNameFix,
        ...extra,
        // 点击的所有页面名称
        page_name: currentPath,
      },
    });
  };

  // 系列 数据

  // 选择课程
  const handleItemClick = (e: any, data: HomeBlogItem) => {
    setCurrentKey(data);
    handleGA('ArticleTabClick', { tab_name: t(data.cat.cat_name) });
    scrollObj.scrollToElement(e.target, 300, true, 0, 'easing');
  };

  // const blogPostsData = useMemo(() => {
  //   return dataTemporary[currentKey.type][i18n.language];
  // }, [currentKey, i18n.language]);

  const handleClick = (data: Post) => {
    handleGA('ArticleClick', {
      article_name: data?.post_title,
      article_type: data?.post_id,
      Article_url: data?.post_link,
    });
  };

  const initBScroll = () => {
    setscrollObj(
      new BScroll(divRef.current as HTMLDivElement, {
        //  可以使用原生的点击
        click: true,
        scrollX: true,
        scrollbar: false,
      }),
    );
  };

  useEffect(() => {
    if (divRef.current) {
      initBScroll();
    }
    return () => {
      scrollObj?.destroy();
    };
  }, [divRef.current]);

  return (
    <section className=" relative pt-5 text-center  lg:pt-[30px] xl:pt-8" data-expose-event={exposeEvent}>
      <div className="container">
        <div className=" absolute left-1/2 top-0 -z-10 h-full w-full translate-x-[-50%] lg:h-[280px] lg:w-[562px] xl:h-[400px] xl:w-[800px]">
          <Image alt="Wukong" width={800} height={400} src="/images/home/blog-bg.png" quality={100} />
        </div>
      </div>
      <div className="container relative z-10">
        <Title className="mb-6 lg:mb-8 xl:mb-12 ">{t('博客-标题')}</Title>
      </div>

      <div className="">
        <div className=" " ref={divRef}>
          <div className=" mx-auto inline-block whitespace-nowrap  p-[16px] text-base md:mb-12  md:overflow-visible lg:rounded-3xl lg:shadow-wk1 lg:backdrop-blur-[4px] xl:mb-15 xl:text-xl">
            {blogList?.map?.((item) => (
              <h3
                key={item.cat.cat_id}
                onClick={(e) => handleItemClick(e, item)}
                className={cls(
                  ' mr-3 inline-block shrink-0  cursor-pointer rounded-xl border-2 bg-[linear-gradient(180deg,_rgba(255,_255,_255,_0.45)_0%,rgba(255,_255,_255,_0.6)_105%)] px-[16px] py-[24pxs] shadow-wk1 backdrop-blur-[4px] last:mr-0  lg:mr-[16px] lg:rounded-2xl lg:bg-none lg:px-6 lg:py-4 lg:shadow-none lg:backdrop-blur-none',
                  currentKey?.cat.cat_id === item.cat.cat_id
                    ? 'border-white [&&]:bg-[linear-gradient(90deg,_rgba(255,_83,_83,_0.08)_9%,rgba(109,_56,_251,_0.08)_61%,rgba(0,_157,_255,_0.08)_100%),_linear-gradient(180deg,_rgba(255,_255,_255,_0.7)_0%,rgba(255,_255,_255,_0.95)_105%)]'
                    : 'border-transparent',
                )}
              >
                {/* <span className="mr-3 inline-block h-7 w-7 text-xl">{item.img}</span> */}
                <span dangerouslySetInnerHTML={{ __html: t(item.cat.cat_name) }} />
              </h3>
            ))}
          </div>
        </div>

        <div className=" container">
          <BlogPostItem post={currentKey?.posts || []} handleClick={handleClick} />
          <div>
            <Button
              traceName="O_Home_BlogModule_MoreClick"
              href={t('path:博客')}
              target="_blank"
              size="xl"
              className="mt-6 lg:mt-12 xl:mt-[60px] [&]:text-base [&]:font-medium [&]:xl:text-xl"
            >
              {t('博客-按钮')}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
