import React from 'react';
import Image from 'next/image';

import { useTranslation } from 'next-i18next';

const OnlineClass = () => {
  const { t, i18n } = useTranslation(['home']);

  const starHtml = () => {
    return (
      <Image
        alt="Wukong"
        className="mx-auto h-9 w-[168px]"
        width={168}
        height={36}
        src="/images/home/banner-star.jpg"
        quality={100}
      />
    );
  };

  return (
    <section className=" relative mt-[90px] lg:mt-36 xl:mt-[118px]" data-expose-event="O_Home_ClassroomVideosExpose">
      <div className="absolute -top-16 left-1/2 w-full translate-x-[-50%]  md:-top-20 lg:-top-52 xl:top-[-310px]">
        <Image
          alt="Wukong"
          className="mx-auto"
          width={1400}
          height={635}
          src="/images/home/honorbg.png"
          quality={100}
        />
      </div>
      {/* ipad */}
      <div className="relative z-10 mx-auto h-[214px] w-[334px] lg:h-[544px] lg:w-[850px] xl:h-[744px] xl:w-[1162px]">
        <div className="absolute left-1/2  top-1/2  z-10 h-50 w-[320px]  -translate-x-1/2 -translate-y-1/2 rounded-[10px] lg:h-[508px] lg:w-[814px] lg:rounded-3xl xl:h-[696px] xl:w-[1114px] xl:rounded-4xl">
          <video
            className="video1  block h-full w-full  rounded-[10px] bg-black  object-cover lg:rounded-[27px] xl:rounded-5xl "
            src={
              i18n?.language === 'zh'
                ? 'https://cdnwukong.com/images/growth/video/home-course-new-zh.mp4'
                : 'https://cdnwukong.com/images/growth/video/home-course-new-en.mp4'
            }
            controlsList="nodownload, nofullscreen"
            playsInline
            x5-video-player-fullscreen="false"
            x5-playsinline="true"
            webkit-playsinline="true"
            loop
            autoPlay
            muted
            contextMenu="return false"
            poster="/images/home/poster-1.png"
            disablePictureInPicture
          />
        </div>
        <div className="absolute left-1/2 top-1/2 h-[214px] w-[334px] -translate-x-1/2 -translate-y-1/2 lg:h-[544px] lg:w-[850px] xl:h-[744px] xl:w-[1162px]">
          <Image alt="Wukong" className="" width={1162} height={744} src="/images/home/banner-ipad.png" />
        </div>
      </div>
      <div className="container mt-10 lg:mt-25 xl:mt-[140px]">
        <div className=" mx-auto rounded-3xl bg-white  px-3 py-6 shadow-wk1 lg:flex lg:w-4/5 lg:flex-col-reverse lg:rounded-5xl lg:px-8 lg:py-10 xl:w-9/12">
          <div className="relative lg:pl-14">
            <div className="mx-auto box-content h-[68px] w-[68px] rounded-full border-4 border-white shadow-[0px_2px_5px_0px_rgba(0,0,0,0.06)] lg:absolute lg:left-[-70px] lg:h-25 lg:w-25 xl:h-25 xl:w-25">
              <Image src="/images/home/onlineClassHead.png" width={100} height={100} alt={t('上课界面-alt')} />
            </div>
            <div className=" mt-6  lg:flex lg:items-center lg:justify-between">
              <div className=" items-baseline text-center text-sm font-medium  lg:flex">
                <p>
                  <span
                    className="[&_strong]:text-base"
                    dangerouslySetInnerHTML={{ __html: t('上课界面-评论-姓名') }}
                  />
                  <span className="px-1.5 text-wk-words-3">|</span>
                  <span className="" dangerouslySetInnerHTML={{ __html: t('上课界面-评论-姓名-2') }} />
                </p>

                <p className="[&_strong]:text-base">
                  <span className="hidden px-1.5 text-wk-words-3 lg:inline-block">|</span>
                  <span className="">{t('上课界面-评论-说明')}</span>
                  <span className="px-1.5 text-wk-words-3">|</span>

                  <span className="">{t('上课界面-评论-国家')}</span>
                  <span className="relative -top-0.5 ml-0.5 inline-block h-[20px] w-[19px] ">
                    <Image
                      src="/images/home/flag-1.png"
                      className=" inline-block"
                      alt="WuKong"
                      width={19}
                      height={20}
                    />
                  </span>
                </p>
              </div>
              <div className="hidden lg:block">{starHtml()}</div>
            </div>
          </div>

          <div className="mt-6   flex lg:mt-0">
            <div className="  self-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="lg:h-8 lg:w-8"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.42592 4.55806C5.66062 6.97617 3.56883 9.79067 3.15055 13.0015C2.49937 18.0001 6.96947 20.4467 9.23442 18.2484C11.4994 16.0501 10.1417 13.2599 8.50162 12.4972C6.86157 11.7344 5.85862 12.0001 6.03357 10.9808C6.20852 9.96162 8.54182 7.13572 10.5917 5.81967C10.7277 5.70402 10.7795 5.47962 10.6485 5.30937C10.5624 5.19742 10.3934 4.97785 10.1417 4.65063C9.92152 4.3645 9.71062 4.37521 9.42592 4.55806Z"
                  fill="#EBECFA"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.339 4.55806C15.5737 6.97617 13.4819 9.79067 13.0636 13.0015C12.4125 18.0001 16.8826 20.4467 19.1475 18.2484C21.4125 16.0501 20.0548 13.2599 18.4147 12.4972C16.7747 11.7344 15.7717 12.0001 15.9467 10.9808C16.1216 9.96162 18.4549 7.13572 20.5048 5.81967C20.6408 5.70402 20.6926 5.47962 20.5616 5.30937C20.4755 5.19742 20.3065 4.97785 20.0548 4.65063C19.8346 4.3645 19.6237 4.37521 19.339 4.55806Z"
                  fill="#EBECFA"
                />
              </svg>
            </div>
            <p
              className="px-2 text-left text-base leading-7 text-wk-words-2 lg:px-6 lg:text-xl  xl:leading-8 [&_strong]:font-semibold [&_strong]:text-wk-words-1"
              dangerouslySetInnerHTML={{ __html: t('上课界面-评论-标题') }}
            />
            <div className="  self-end">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.5741 19.4413C18.3394 17.0232 20.4312 14.2087 20.8495 10.9979C21.5006 5.99932 17.0305 3.55267 14.7656 5.75097C12.5006 7.94927 13.8583 10.7395 15.4984 11.5022C17.1384 12.265 18.1414 11.9993 17.9664 13.0186C17.7915 14.0378 15.4582 16.8637 13.4083 18.1797C13.2723 18.2954 13.2205 18.5198 13.3515 18.69C13.4376 18.802 13.6066 19.0215 13.8583 19.3488C14.0785 19.6349 14.2894 19.6242 14.5741 19.4413Z"
                  fill="#EBECFA"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.66053 19.4413C8.42578 17.0232 10.5176 14.2087 10.9359 10.9979C11.587 5.99932 7.11693 3.55267 4.85198 5.75097C2.58703 7.94927 3.94473 10.7395 5.58478 11.5022C7.22483 12.265 8.22783 11.9993 8.05283 13.0186C7.87788 14.0378 5.54458 16.8637 3.49468 18.1797C3.35868 18.2954 3.30693 18.5198 3.43793 18.69C3.52403 18.802 3.69298 19.0215 3.94473 19.3488C4.16488 19.6349 4.37578 19.6242 4.66053 19.4413Z"
                  fill="#EBECFA"
                />
              </svg>
            </div>
          </div>
          <div className=" mt-6 lg:mt-0 lg:hidden">{starHtml()}</div>
        </div>
      </div>
    </section>
  );
};

export default OnlineClass;
