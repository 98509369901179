import { Tag } from '@/request/interface/common';
import { useEffect, useRef, useState } from 'react';

const Item = ({ tags }: { tags: Tag[] }) => {
  const [children, setChildren] = useState<Tag[]>(tags);
  const parentRef = useRef<HTMLDivElement>(null);
  const childrenRefs = useRef<HTMLSpanElement[]>([]);

  useEffect(() => {
    const parentWidth = parentRef.current?.getBoundingClientRect().width || 0;
    const totalChildrenWidth = childrenRefs.current.reduce((total, childRef) => {
      return total + (childRef?.getBoundingClientRect().width || 0);
    }, 0);

    if (totalChildrenWidth > parentWidth) {
      setChildren((prevChildren) => prevChildren.slice(0, -1)); // 删除最后一个子元素
    }
  }, [children]);

  return (
    <div ref={parentRef} className="mt-3 whitespace-nowrap text-left text-[14px] text-[#484C70] lg:mt-4">
      {children.map(({ tag_id, tag_name, tag_link }, index) => (
        <span
          // eslint-disable-next-line no-return-assign
          ref={(el) => (childrenRefs.current[index] = el!)}
          onClick={() => window.open(tag_link, '_blank')}
          key={tag_id}
          className="mr-[4px] rounded-[99px] bg-[#F5F5FC] px-[8px] py-[4px] last:mr-0  last:mr-0 hover:bg-[#1B1F4D] hover:text-[#fff]"
        >
          # {tag_name}
        </span>
      ))}
    </div>
  );
};

export default Item;
