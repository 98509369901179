import React, { useContext, useState } from 'react';
import Title from '@/components/Title';
import Button from '@/components/Button';
import { MyContext } from '@/config/context-manager';
import Image from 'next/image';
import { i18n, useTranslation } from 'next-i18next';
import LeadButton from '@/components/LeadButton';
import cls from 'classnames';
import Modal from '@/components/ModalNew';
import classNames from 'classnames';

const qualificationConfig = [
  {
    data: '老师-标准1-数据',
    dataDescription: '老师-标准1-描述',
    id: 1,
  },
  {
    data: '老师-标准2-数据',
    dataDescription: '老师-标准2-描述',
    id: 2,
  },
  {
    data: '老师-标准3-数据',
    dataDescription: '老师-标准3-描述',
    id: 3,
  },
  {
    data: '老师-标准4-数据',
    dataDescription: '老师-标准4-描述',
    id: 4,
  },
];

interface Teacher {
  name: string;
  school: string;
  teachingAge: string;
  description: string;
  img: string;
  videoUrl: string;
  imgAlt: string;
  id: number;
}

const teachersConfig: Teacher[] = [
  {
    name: '老师-1-姓名',
    school: '老师-1-学校',
    teachingAge: '老师-1-教龄',
    description: '老师-1-介绍',
    img: '/images/home/teachers-head-1.png',
    videoUrl: 'https://cdnwukong.com/images/growth/video/home-teacher-1.mp4',
    id: 1,
    imgAlt: '老师-alt-中文',
  },
  {
    name: '老师-2-姓名',
    school: '老师-2-学校',
    teachingAge: '老师-2-教龄',
    description: '老师-2-介绍',
    img: '/images/home/teachers-head-2.png',
    videoUrl: 'https://cdnwukong.com/images/growth/video/home-teacher-2.mp4',
    id: 2,
    imgAlt: '老师-alt-数学',
  },
  {
    name: '老师-3-姓名',
    school: '老师-3-学校',
    teachingAge: '老师-3-教龄',
    description: '老师-3-介绍',
    img: '/images/home/teachers-head-3.png',
    videoUrl: 'https://cdnwukong.com/images/growth/home-teacher-3.mp4',
    id: 3,
    imgAlt: '老师-alt-英文',
  },
];

const Teachers = () => {
  const { t } = useTranslation(['home']);
  const { locale } = useContext(MyContext);

  const [isModalVisible, setModalVisible] = useState(false);
  const [modelData, setModelData] = useState<Teacher>();
  const [autoPlay, setAutoPlay] = useState(false);

  const handleOpenModal = (item: Teacher) => {
    setModalVisible(true);
    setAutoPlay(true);
    setModelData(item);
  };
  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <section
      className=" relative mt-[120px] text-center lg:mt-[275px] xl:mt-[370px]"
      data-expose-event="O_Home_TeacherIntroductionExpose"
    >
      <div className="container">
        <div className="absolute -top-25 left-1/2 w-[150%] translate-x-[-50%] opacity-50 lg:-top-60 lg:w-full lg:opacity-100 xl:-top-[366px]">
          <Image
            alt="Wukong"
            width={1070}
            height={1070}
            src="/images/home/teachers-bg.png"
            quality={85}
            className="mx-auto"
          />
        </div>
        <Title className="" innerHTML theme="colorful">
          {t('老师-标题')}
        </Title>
        {locale === 'en' && (
          <p className={cls('subTitleMaxWidth mt-5 text-base lg:mt-10 lg:text-xl xl:text-[28px]')}>
            {t('老师-副标题')}
          </p>
        )}
      </div>

      <div className="relative z-10">
        <div className="container">
          {/* 资格 */}
          <ul className=" mt-6 grid grid-cols-2 gap-4 lg:mt-12 lg:grid-cols-4 lg:gap-5 xl:gap-10">
            {qualificationConfig.map((item) => (
              <li
                key={item.id}
                className="  relative rounded-3xl border-2 border-white  bg-[linear-gradient(184deg,_rgba(255,_83,_83,_0.03)_9%,rgba(109,_56,_251,_0.03)_62%,rgba(0,_157,_255,_0.03)_93%),_linear-gradient(180deg,_rgba(255,_255,_255,_0.4)_0%,rgba(255,_255,_255,_0.9)_100%)] px-3 py-2.5 shadow-wk1 backdrop-blur-md  lg:px-8 lg:py-6 xl:rounded-5xl"
              >
                <p className="text-[28px] font-black xl:text-[34px]">{t(item.data)}</p>
                <p className="mt-2 text-sm text-wk-words-5 lg:text-base xl:text-xl">{t(item.dataDescription)}</p>
              </li>
            ))}
          </ul>
          {/* 老师 1920 显示 */}
          <div className="hidden xl:block">
            <ul className="mt-[120px] flex  justify-center space-x-6 ">
              {teachersConfig.map((item) => (
                <li key={item.id} className="">
                  <div className="linearGradient2 relative  h-[279px] w-[452px] rounded-5xl border-2 border-white shadow-wk2 ">
                    <div className="flex h-full bg-[url('/images/home/teachers-head-bg.png')]   bg-[length:239px_279px] bg-left-top bg-no-repeat">
                      {/* 老师图片 */}
                      <div className="relative  w-[190px]">
                        <Image
                          className="absolute bottom-0 left-0 h-[255px] w-[190px]"
                          alt={t(item.imgAlt)}
                          width={190}
                          height={255}
                          src={item.img}
                          // quality={100}
                        />
                      </div>
                      {/* 信息 */}
                      <div className=" flex-1 py-6 pl-1 pr-5 text-left">
                        <h3>
                          <p className="text-[28px] font-semibold">{t(item?.name)}</p>
                          <p
                            className=" mt-1 text-base text-wk-words-5"
                            dangerouslySetInnerHTML={{ __html: t(item?.school) }}
                          />
                        </h3>
                        <p className="text-base text-wk-words-5">{t(item?.teachingAge)}</p>
                        <p
                          className={classNames(
                            'mt-3 text-sm leading-[22px]',
                            i18n?.language === 'en' ? 'line-clamp-3' : 'line-clamp-5',
                          )}
                          title={t(item?.description)}
                        >
                          {t(item?.description)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <Button
                      theme="while"
                      onClick={() => {
                        handleOpenModal(item);
                      }}
                      traceName={`O_Home_TeacherModule_Video${item?.id}Click`}
                      traceData={{ video_name: t(item?.name) || '', button_name: t('老师-按钮-观看') }}
                      className=" relative  h-11 border-solid border-white bg-[linear-gradient(180deg,_rgba(255,_255,_255,_0.6)_0%,#ffffff_100%)] pl-6 pr-15 text-sm shadow-wk1 backdrop-blur-[4px]"
                    >
                      <>
                        <span>{t('老师-按钮-观看')}</span>
                        <Image
                          className="absolute right-0 top-0 h-11 w-11"
                          alt=""
                          width={44}
                          height={44}
                          src="/images/home/teachers-paly.png"
                          quality={100}
                        />
                      </>
                    </Button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* 移动端老师样式 */}
        <div className="mt-6 overflow-hidden lg:mx-auto lg:mt-20  lg:max-w-screen-lg lg:overflow-visible xl:hidden ">
          <ul className="hideScrollbar  flex flex-row items-stretch space-x-5 overflow-x-scroll px-5 pb-6  lg:justify-center lg:space-x-4 lg:overflow-visible lg:px-0 lg:pb-0">
            {teachersConfig.map((item) => (
              <li className="lg:[317px] flex w-[280px] shrink-0 flex-col lg:flex-1 " key={item.id}>
                <div className="linearGradient2  relative h-[200px] w-full rounded-3xl border-2 border-white shadow-wk2 ">
                  <div className="flex h-full bg-[url('/images/home/teachers-head-bg.png')]   bg-[length:180px_209px]  bg-[-4px_10px] bg-no-repeat">
                    {/* 老师图片 */}
                    <div className="relative w-[125px]">
                      <Image
                        className="absolute bottom-0 left-0 h-[169px] w-[125px] "
                        width={190}
                        height={255}
                        src={item.img}
                        alt={t(item.imgAlt)}
                      />
                    </div>
                    {/* 信息 */}
                    <h3 className="flex-1 pl-1 pr-5 pt-10 text-left">
                      <p className="text-xl  font-semibold">{t(item?.name)}</p>
                      <p className=" mt-1 text-sm text-wk-words-5">{t(item?.school)}</p>
                      <p className="text-sm  text-wk-words-5">{t(item?.teachingAge)}</p>
                    </h3>
                  </div>
                </div>
                <div className=" relative z-10 mt-[-30px] flex w-full flex-1 flex-col justify-between rounded-3xl bg-white p-5 shadow-wk1">
                  {/* 信息 */}
                  <p className="text-left text-sm leading-6">{t(item?.description)}</p>
                  <div className="mt-3">
                    <Button
                      theme="while"
                      onClick={() => {
                        handleOpenModal(item);
                      }}
                      traceName={`O_Home_TeacherModule_Video${item?.id}Click`}
                      traceData={{ video_name: t(item?.name) || '', button_name: t('老师-按钮-观看') }}
                      className=" relative h-11 border-solid border-white bg-[linear-gradient(180deg,_rgba(255,_255,_255,_0.6)_0%,#ffffff_100%)] pl-6 pr-15 text-sm shadow-wk1 backdrop-blur-[4px]"
                    >
                      <>
                        <strong className="font-normal">{t('老师-按钮-观看')}</strong>
                        <Image
                          className="absolute right-0 top-0 h-11 w-11"
                          alt=""
                          width={44}
                          height={44}
                          src="/images/home/teachers-paly.png"
                          quality={100}
                        />
                      </>
                    </Button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <LeadButton
            traceName="O_Home_TeacherModule_CTAClick"
            traceGa4Name="new_entry_HomePage_TeacherModuleCTA"
            theme="colorfulBorder"
            size="xl"
            className=" lg:mt-15 xl:mt-20"
          >
            {t('老师-按钮')}
          </LeadButton>
        </div>
      </div>
      <Modal
        isOpen={isModalVisible}
        onClose={handleCloseModal}
        classNamePanel="[&]:lg:max-w-[400px] [&]:xl:max-w-2xl  [&]:text-wk-words-1 "
      >
        {/* <div className="h-[170px] w-[300px] rounded-2xl bg-wk-border-3 lg:h-[339px] lg:w-[600px]" /> */}
        <div
          className="aspect-h-4 aspect-w-7 relative h-0  rounded-2xl bg-wk-border-3 "
          data-expose-event="O_Home_TeacherVideosPopExpose"
          data-expose-data={JSON.stringify({ video_name: t(modelData?.name || '') })}
        >
          {modelData?.videoUrl && (
            <video
              key={modelData?.id}
              className=" h-full w-full rounded-2xl bg-wk-border-3 object-cover"
              src={modelData?.videoUrl}
              controls
              controlsList="nodownload"
              playsInline
              loop
              autoPlay={autoPlay}
            />
          )}
        </div>
        <p className="mt-4 text-xl font-semibold lg:mt-6 lg:text-[28px] xl:mt-10">{t(modelData?.name || '')}</p>
        <p className=" mt-1 text-sm text-wk-words-5 xl:text-base">{t(modelData?.school || '')}</p>
        <p className=" text-sm text-wk-words-5 xl:text-base">{t(modelData?.teachingAge || '')}</p>
        <p className="mt-3 text-sm  leading-[22px] lg:text-xs lg:leading-5 xl:text-sm xl:leading-[22px]">
          {t(modelData?.description || '')}
        </p>
        <div className="text-center">
          <LeadButton
            theme="colorful"
            traceName={`O_Home_TeacherModule_Video${modelData?.id}CTAClick`}
            traceGa4Name={`new_entry_HomePage_TeacherPop${modelData?.id}CTA`}
            traceData={{ video_name: t(modelData?.name || '') }}
            className=" mt-4 w-full py-4 text-center text-lg font-bold lg:mt-6 lg:w-auto lg:px-15 xl:mt-10 "
          >
            {t('老师-按钮')}
          </LeadButton>
        </div>
      </Modal>
    </section>
  );
};

export default Teachers;
