import React from 'react';

const Eye = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M8.00016 12.5C11.6821 12.5 14.6668 8.5 14.6668 8.5C14.6668 8.5 11.6821 4.5 8.00016 4.5C4.31826 4.5 1.3335 8.5 1.3335 8.5C1.3335 8.5 4.31826 12.5 8.00016 12.5Z"
        stroke="#8D8FA6"
        stroke-linejoin="round"
      />
      <path
        d="M8.00016 10.1667C8.92063 10.1667 9.66683 9.42051 9.66683 8.50004C9.66683 7.57957 8.92063 6.83337 8.00016 6.83337C7.0797 6.83337 6.3335 7.57957 6.3335 8.50004C6.3335 9.42051 7.0797 10.1667 8.00016 10.1667Z"
        stroke="#8D8FA6"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Eye;
