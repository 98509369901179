import React, { useContext } from 'react';
import Title from '@/components/Title';
import Image, { StaticImageData } from 'next/image';
import { MyContext } from '@/config/context-manager';
import cls from 'classnames';
import Marquee from 'react-fast-marquee';

import { useTranslation } from 'next-i18next';
import userReviewsImg1 from '@public/images/home/userReviews-img1.png';
import userReviewsImg2 from '@public/images/home/userReviews-img2.png';
import userReviewsImg3 from '@public/images/home/userReviews-img3.png';
import userReviewsImg4 from '@public/images/home/userReviews-img4.png';
import userReviewsImg5 from '@public/images/home/userReviews-img5.png';
import userReviewsImg6 from '@public/images/home/userReviews-img6.jpg';
import userReviewsImg7 from '@public/images/home/userReviews-img7.png';
import LeadButton from '@/components/LeadButton';
import TPLink from '@/components/TPLink';

interface IConfig {
  // 图片
  // tp
  // 评价
  type: 'img' | 'tp' | 'text';
  img?: StaticImageData;
  name?: string;
  country?: string;
  flag?: string;
  flagEn?: string;
  appraise?: string;
}
const config: IConfig[][] = [
  [
    {
      type: 'img',
      img: userReviewsImg1,
    },
    {
      type: 'text',
      name: '用户评价-item1-姓名',
      country: '用户评价-item1-国家',
      flag: '/images/home/flag-4.png',
      flagEn: '/images/home/flag-6.png',
      appraise: '用户评价-item1-描述',
    },
    {
      type: 'img',
      img: userReviewsImg6,
    },
    {
      type: 'tp',
    },

    {
      type: 'img',
      img: userReviewsImg3,
    },
    {
      type: 'img',
      img: userReviewsImg2,
    },
    {
      type: 'text',
      name: '用户评价-item2-姓名',
      country: '用户评价-item2-国家',
      appraise: '用户评价-item2-描述',
      flag: '/images/home/flag-1.png',
      flagEn: '/images/home/flag-1.png',
    },
  ],
  [
    {
      type: 'img',
      img: userReviewsImg7,
    },
    {
      type: 'text',
      name: '用户评价-item3-姓名',
      country: '用户评价-item3-国家',
      appraise: '用户评价-item3-描述',
      flag: '/images/home/flag-8.png',
      flagEn: '/images/home/flag-1.png',
    },
    {
      type: 'img',
      img: userReviewsImg4,
    },
    {
      type: 'text',
      name: '用户评价-item4-姓名',
      country: '用户评价-item4-国家',
      appraise: '用户评价-item4-描述',
      flag: '/images/home/flag-2.png',
      flagEn: '/images/home/flag-5.png',
    },
    {
      type: 'img',
      img: userReviewsImg5,
    },
    {
      type: 'tp',
    },
  ],
];

const UserReviews = () => {
  const { locale } = useContext(MyContext);
  const { t, i18n } = useTranslation(['home']);

  const node = (child: IConfig[], index: number) => {
    const directionArr = ['left', 'right'];
    const direction = directionArr[index];
    const base = 'rounded-5xl h-full mx-1.5 lg:mx-3 shrink-0 shadow-wk1';
    return (
      <div className="overflow-hidden">
        <Marquee direction={direction as 'left' | 'right'}>
          <ul className=" hideScrollbar  box-content  flex h-[176px] w-full  pb-3 lg:h-[164px] lg:pb-6  xl:h-[200px]  ">
            {child?.map((item, i) => {
              if (item.type === 'img') {
                return (
                  <li key={i} className={cls(base)}>
                    {item?.img && (
                      <Image
                        src={item.img}
                        alt={t('用户评价-alt')}
                        sizes="(max-width: 768px) 100vw, 33vw"
                        className="h-full w-auto  rounded-5xl "
                      />
                    )}
                  </li>
                );
              }
              if (item.type === 'tp') {
                return (
                  <TPLink key={i}>
                    <li className={cls(base, 'flex w-[220px] flex-col  items-center justify-center lg:w-[300px]')}>
                      <Image
                        alt="WuKong userReviews"
                        className="h-[28px] w-[166px] lg:h-[34px] lg:w-[200px]"
                        width={200}
                        height={34}
                        src="/images/home/userReviews-tp.png"
                      />
                      <Image
                        alt="WuKong userReviews"
                        width={200}
                        height={34}
                        className="mt-[10px] h-[28px] w-[166px] lg:mt-4 lg:h-[34px] lg:w-[200px]"
                        src="/images/home/userReviews-tp2.jpg"
                      />
                      <p className=" mt-[12px] flex w-[166px] items-center text-xs  lg:mt-5  lg:w-[200px]  lg:text-sm">
                        <span
                          className="lg:[&_span]:text-base"
                          dangerouslySetInnerHTML={{ __html: t('用户评价-tp-1') }}
                        />
                        {/* <span className="px-1 text-wk-words-3 lg:px-1.5">|</span>
                      <span
                        className="lg:[&_span]:text-base"
                        dangerouslySetInnerHTML={{ __html: t('用户评价-tp-2') }}
                      /> */}
                      </p>
                    </li>
                  </TPLink>
                );
              }
              if (item.type === 'text') {
                return (
                  <li
                    key={i}
                    className={cls(
                      base,
                      'w-[300px]  px-3  pb-3 pt-2.5 lg:w-[400px] lg:px-6 lg:pb-6 lg:pt-5 xl:w-[450px] ',
                    )}
                  >
                    <p className=" flex items-center  pl-10 text-left">
                      <span
                        className="text-sm font-extrabold lg:text-base"
                        dangerouslySetInnerHTML={{ __html: t(item.name || '') }}
                      />
                      <span className="px-1.5 text-wk-words-3">|</span>
                      <span className="text-sm font-medium">{t(item?.country || '')}</span>
                      {item.flag && item.flagEn && (
                        <span className="ml-0.5 inline-block h-[20px] w-[19px]">
                          <Image
                            src={i18n.language === 'zh' ? item.flag : item.flagEn}
                            alt="WuKong"
                            width={19}
                            height={20}
                          />
                        </span>
                      )}
                    </p>
                    <div className="mt-0.5 flex lg:mt-2.5  ">
                      <div className="  self-start">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.42592 4.55806C5.66062 6.97617 3.56883 9.79067 3.15055 13.0015C2.49937 18.0001 6.96947 20.4467 9.23442 18.2484C11.4994 16.0501 10.1417 13.2599 8.50162 12.4972C6.86157 11.7344 5.85862 12.0001 6.03357 10.9808C6.20852 9.96162 8.54182 7.13572 10.5917 5.81967C10.7277 5.70402 10.7795 5.47962 10.6485 5.30937C10.5624 5.19742 10.3934 4.97785 10.1417 4.65063C9.92152 4.3645 9.71062 4.37521 9.42592 4.55806Z"
                            fill="#EBECFA"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M19.339 4.55806C15.5737 6.97617 13.4819 9.79067 13.0636 13.0015C12.4125 18.0001 16.8826 20.4467 19.1475 18.2484C21.4125 16.0501 20.0548 13.2599 18.4147 12.4972C16.7747 11.7344 15.7717 12.0001 15.9467 10.9808C16.1216 9.96162 18.4549 7.13572 20.5048 5.81967C20.6408 5.70402 20.6926 5.47962 20.5616 5.30937C20.4755 5.19742 20.3065 4.97785 20.0548 4.65063C19.8346 4.3645 19.6237 4.37521 19.339 4.55806Z"
                            fill="#EBECFA"
                          />
                        </svg>
                      </div>
                      <p className="line-clamp-[6] px-4 text-left text-xs leading-5 text-wk-words-2 lg:line-clamp-[4]  lg:text-sm lg:leading-[22px] xl:line-clamp-[5] xl:text-base">
                        {t(item.appraise || '')}
                      </p>
                      <div className="  self-end">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.5741 19.4413C18.3394 17.0232 20.4312 14.2087 20.8495 10.9979C21.5006 5.99932 17.0305 3.55267 14.7656 5.75097C12.5006 7.94927 13.8583 10.7395 15.4984 11.5022C17.1384 12.265 18.1414 11.9993 17.9664 13.0186C17.7915 14.0378 15.4582 16.8637 13.4083 18.1797C13.2723 18.2954 13.2205 18.5198 13.3515 18.69C13.4376 18.802 13.6066 19.0215 13.8583 19.3488C14.0785 19.6349 14.2894 19.6242 14.5741 19.4413Z"
                            fill="#EBECFA"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.66053 19.4413C8.42578 17.0232 10.5176 14.2087 10.9359 10.9979C11.587 5.99932 7.11693 3.55267 4.85198 5.75097C2.58703 7.94927 3.94473 10.7395 5.58478 11.5022C7.22483 12.265 8.22783 11.9993 8.05283 13.0186C7.87788 14.0378 5.54458 16.8637 3.49468 18.1797C3.35868 18.2954 3.30693 18.5198 3.43793 18.69C3.52403 18.802 3.69298 19.0215 3.94473 19.3488C4.16488 19.6349 4.37578 19.6242 4.66053 19.4413Z"
                            fill="#EBECFA"
                          />
                        </svg>
                      </div>
                    </div>
                  </li>
                );
              }
              return <li key={i} />;
            })}
          </ul>
        </Marquee>
      </div>
    );
  };

  return (
    <section className="marginModelTop2" data-expose-event="O_Home_EvaluationExpose">
      <div className="container">
        <Title className="marginTitleBottom relative" innerHTML theme="colorful">
          {`${t(
            '用户评价-标题',
          )} <div class=' lg:inline-block relative  lg:after:h-[122px] lg:after:w-[122px] xl:after:h-[188px] xl:after:w-[188px] after:bg-[url("/images/home/userReviews-icon.png")] after:bg-cover after:bg-no-repeat lg:after:block after:absolute lg:after:top-[-77px]  lg:after:right-[-104px] xl:after:top-[-120px]  xl:after:right-[-150px]' />`}
        </Title>
        {locale === 'en' && (
          <p
            className={cls(
              'subTitleMaxWidth mb-6 mt-6 text-center text-base lg:mb-12 lg:mt-12 lg:text-xl xl:text-[28px]',
            )}
          >
            {t('用户评价-副标题')}
          </p>
        )}
      </div>

      <div className="">
        <div className=" text-center">
          <div className="">
            {config?.map((item, i) => {
              const childNode = node(item, i);
              return (
                <div key={i} className="[&_ul]:last:pb-6 [&_ul]:last:lg:pb-15 [&_ul]:last:xl:pb-20">
                  {childNode}
                </div>
              );
            })}
          </div>
          <LeadButton
            theme="colorfulBorder"
            traceName="O_Home_ReviewModule_CTAClick"
            traceGa4Name="new_entry_HomePage_ReviewModuleCTA"
            size="xl"
            className=""
          >
            {t('用户评价-按钮')}
          </LeadButton>
        </div>
      </div>
    </section>
  );
};

export default UserReviews;
