import { Dialog, Transition } from '@headlessui/react';
import cls from 'classnames';
import { Fragment } from 'react';

interface MyModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  classNamePanel?: string;
}

const MyModal: React.FC<MyModalProps> = ({ isOpen = false, onClose, children, classNamePanel }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed  bottom-0 left-0 right-0 top-0 bg-black/70 " />
        </Transition.Child>

        <div className="fixed  bottom-0 left-0 right-0 top-0 overflow-y-auto">
          <div className="relative flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={cls(
                  'relative w-full max-w-md transform rounded-2xl bg-white p-5 text-left align-middle shadow-xl transition-all lg:p-6 xl:p-10',
                  classNamePanel,
                )}
              >
                <svg
                  onClick={onClose}
                  className="absolute right-2 top-2 hidden h-4 w-4 cursor-pointer lg:block xl:right-4 xl:top-4 xl:h-6 xl:w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M7 7L17 17" stroke="#8D8FA6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M7 17L17 7" stroke="#8D8FA6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <svg
                  onClick={onClose}
                  className="absolute bottom-[-50px] left-1/2  right-4 translate-x-[-50%] cursor-pointer lg:hidden"
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="36"
                  viewBox="0 0 37 36"
                  fill="none"
                >
                  <path
                    d="M18.5 33C26.7843 33 33.5 26.2843 33.5 18C33.5 9.71572 26.7843 3 18.5 3C10.2157 3 3.5 9.71572 3.5 18C3.5 26.2843 10.2157 33 18.5 33Z"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22.7426 13.7578L14.2573 22.2431"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.2576 13.7578L22.7428 22.2431"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default MyModal;
